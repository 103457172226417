import Link from "next/link";

/**
 * 建立<a>並以此作為routing，以強化SEO分數
 * @param {object} props 
 * @param {string} props.href 實際導向的網址
 * @param {string} props.as 肉眼上看到的網址
 * @param {function} props.onClick 點擊跳轉處時要執行的callback
 * @returns 
 */
function LinkHelper(props){
  const {
    href = "#",
    as = href,
    onClick = ()=>{},
    blockHrefRedirection = false,
    className = "",
    ...restProps
  } = props;

  if(props.href){
    return (
      <Link href={href} as={as}>
        <a 
          className={`linkHelper_container ${className}`} 
          onClick={(e)=>{
            if(blockHrefRedirection){
              e.preventDefault();
            }
            onClick();
          }}
          {...restProps}
        >
          {props.children}
        </a>
      </Link>
    );
  } else {
    return <>{props.children}</>
  }
}

export default LinkHelper;