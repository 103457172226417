import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Popover, Select, Modal } from "antd";
import ImageWithFallback from "@/ImageWithFallback";
import SeoFooterContainer from "@/Footer/SeoContainer";
import { getCMSFooter } from "$STORE/thunk/commonDataThunk";
import LazyLoad from "react-lazyload";
import LinkHelper from "@/Common/LinkHelper"

const ImageHoverItem = ({ item, type }) => {
    // 取得 hover 和 default 圖片
    const deskImg = item?.desktop_image || "/th/img/logo.svg";
    const deskImgHover = item?.desktop_image_hover || "/th/img/logo.svg";

    const [currentImg, setCurrentImg] = useState(deskImg);

    // 定義 hover 時切換圖片的事件
    const handleMouseEnter = () => setCurrentImg(deskImgHover);
    const handleMouseLeave = () => setCurrentImg(deskImg);

    const dynamicHref = item?.desktop_forwarding_url || undefined ; 


    return type === "platform" ? (
      <LinkHelper 
        {...(dynamicHref ? { href: dynamicHref } : {})}
        blockHrefRedirection
     >
      <Col
            className="platform-item"
            span={3}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
                const url = item?.desktop_forwarding_url;
                if (url) {
                    window.open(url, "_blank");
                }
            }}
        >
            <ImageWithFallback
                src={currentImg}
                width={140}
                height={36}
                alt={item.desktop_text || "footer-icon"}
                fallbackSrc="/th/img/logo.svg"
            />
        </Col>
      </LinkHelper> 
    ) : (
      <Popover
            content={item.desktop_text}
            title=""
            trigger="hover"
            overlayClassName="footer-popover"
        >
            
            <LinkHelper 
             {...(dynamicHref ? { href: dynamicHref } : {})}
             blockHrefRedirection
            >
              <li
                  className="icon-item2"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                        const url = item?.desktop_forwarding_url;
                        if (url) {
                            window.open(url, "_blank");
                        }
                        if (type === "social_media") {
                            Pushgtagdata("Home", "Contact CS", "Home_Footer_C_CS");
                            Modal.info({
                                title: ``,
                                className: "socialmedia-line-modal",
                                centered: true,
                                maskClosable: true,
                                width: "fit-content",
                                content: (
                                    <div>
                                        <img
                                            src={item?.desktop_secondary_image}
                                            alt={item.desktop_text}
                                        />
                                    </div>
                                ),
                            });
                        }
                  }}
                >
                  <img src={currentImg} alt={item.desktop_text} />
              </li>
            </LinkHelper>
           
      </Popover>
    );
};

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.getCMSFooter();
    }
    render() {
        const { isHomePage, footerData } = this.props;
        return (
            <React.Fragment>
                {isHomePage && (
                    <LazyLoad height={454} offset={100}>
                        <div className="common-distance-wrap cooperate-wrap">
                            <div className="common-distance clear-padding">
                                <h1 className="cooperate-title">หุ้นส่วน</h1>
                                {/* 合作伙伴 */}
                                <Row
                                    type="flex"
                                    justify="center"
                                    align="bottom"
                                >
                                    {Array.isArray(footerData?.sponsorship)
                                        ? footerData?.sponsorship?.map(
                                              (item, index) => {
                                                // 根据 index 动态调整图片比例
                                                const width = index === 0 ? 480 : index === 1 ? 628 : 2;
                                                const height = index === 0 ? 299 : index === 1 ? 319 : 1;

                                                  return (
                                                      <Col
                                                          key={`sponsorship${index}`}
                                                          span={index === 0 ? 10 : 12}
                                                          offset={index === 0 ? 2 : 0}
                                                          onClick={() => {
                                                              const url = item?.desktop_forwarding_url;
                                                              if (url) {
                                                                  window.open(url, "_blank");
                                                              }
                                                          }}
                                                      >
                                                          <LazyLoad
                                                              height={808}
                                                              offset={100}
                                                              once
                                                          >
                                                              <ImageWithFallback
                                                                  src={item?.desktop_image || "/th/img/logo.svg"}
                                                                  width={width}
                                                                  height={height}
                                                                  alt="app-picture"
                                                                  fallbackSrc="/th/img/logo.svg"
                                                              />
                                                          </LazyLoad>
                                                      </Col>
                                                  );
                                              }
                                          )
                                        : null}
                                </Row>
                            </div>
                        </div>
                    </LazyLoad>
                )}
                <div className="common-distance-wrap footer-platform-wrap">
                    {!this.props.isLogin
                        && this.props.showFooter
                        && this.props.seoFooter
                        && this.props.seoFooter !== "null"
                        && (
                        <SeoFooterContainer seocontent={this.props.seoFooter} />
                    )}
                    <div className="common-distance">
                        <Row className="footer-platform">
                            <Col span={!this.props.isLogin ? 8 : 10}>
                                <h4>ทางเลือกการจ่ายเงิน</h4>
                                {/* 支付方式 */}
                                <ul className="icon-list payment_options">
                                    {footerData?.payment_options?.map(
                                        (item, i) => {
                                            return (
                                                <ImageHoverItem
                                                    key={`payment${i}`}
                                                    item={item}
                                                />
                                            );
                                        }
                                    )}
                                </ul>
                            </Col>
                            <Col span={!this.props.isLogin ? 4 : 5}>
                                <h5>โซเชียลมีเดีย</h5>
                                {/* 关注我们 */}
                                <ul className="icon-list social_media">
                                    {footerData?.social_media?.map(
                                        (item, i) => {
                                            return (
                                                <ImageHoverItem
                                                    key={`social_media${i}`}
                                                    item={item}
                                                    type={"social_media"}
                                                />
                                            );
                                        }
                                    )}
                                </ul>
                            </Col>
                            <Col span={!this.props.isLogin ? 4 : 5}>
                                <h5>ความรับผิดชอบ</h5>
                                {/* 博彩责任 */}
                                <ul className="icon-list regulation">
                                    {footerData?.regulation?.map((item, i) => {
                                        return (
                                            <ImageHoverItem
                                                key={`regulation${i}`}
                                                item={item}
                                            />
                                        );
                                    })}
                                </ul>
                            </Col>
                            <Col span={4}>
                                <h5>ความปลอดภัย</h5>
                                {/* 信息安全 */}
                                <ul className="icon-list info_security">
                                    {footerData?.info_security?.map(
                                        (item, i) => {
                                            return (
                                                <ImageHoverItem
                                                    key={`info_security${i}`}
                                                    item={item}
                                                />
                                            );
                                        }
                                    )}
                                </ul>
                            </Col>
                            
                            {!this.props.isLogin && (
                                 <Col span={4}>
                                    {/* <h5>语言</h5> */}
                                    <Select
                                        size="small"
                                        dropdownClassName="footer-small-drop"
                                        suffixIcon={
                                            <img src="/th/img/selfexclusion/icon.svg" />
                                        }
                                        defaultValue="th"
                                        onChange={(val) => {
                                            Pushgtagdata(
                                                "Home",
                                                "Switch Language",
                                                `Home_Footer_C_${val.toUpperCase()}`
                                            );
                                            window.location.href = `/${val}`;
                                        }}
                                    >
                                   
                                            <Select.Option
                                                value="cn"
                                                className="tlc-sprite item"
                                            >
                                                <LinkHelper href='/cn' blockHrefRedirection >
                                                  <img src="/th/img/home/footer/china.png" />
                                                  <span>中文</span>
                                                </LinkHelper>
                                            </Select.Option>
                                     
                                       
                                        <Select.Option
                                            value="th"
                                            className="tlc-sprite item"
                                        >
                                            <LinkHelper  href='/th' blockHrefRedirection >
                                              <img src="/th/img/home/footer/thailand.png" />
                                              <span>ภาษาไทย</span>
                                            </LinkHelper>
                                        </Select.Option>

                                        <Select.Option
                                            value="vn"
                                            className="tlc-sprite item"
                                        >
                                            <LinkHelper  href='/vn' blockHrefRedirection >
                                                <img src="/th/img/home/footer/vietnam.png" />
                                                <span>Tiếng Việt</span>
                                            </LinkHelper>
                                        </Select.Option>
                                    
                                    </Select>
                               
                                    
                                    {/* {suffixIcon={<CaretDownFilled />}} */}
                                </Col>  
                            )}
                        </Row>
                        <div>
                            <h4>ผู้ให้บริการ</h4>
                            {/* 游戏平台 */}
                            <Row gutter={16}>
                                {footerData?.gaming_platform?.map((item, i) => {
                                    return (
                                        <ImageHoverItem
                                            key={`gaming_platform${i}`}
                                            item={item}
                                            type={"platform"}
                                        />
                                    );
                                })}
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="common-distance-wrap footer-copyright-wrap">
                    <div className="common-distance clear-padding">
                        <div className="copyright-content">
                            <p>FUN88 ดำเนินการโดย Omega Sports Solutions N.V.</p>
                            <p>
                                FUN88 ได้รับอนุญาตและอยู่ภายใต้การกำกับดูแลของ Curaçao Gaming Control Board
                            </p>
                            <p>สงวนลิขสิทธิ์โดย FUN88 ©</p>
                            <p className="copyright-image">
                                <a href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJak5LY0ZSWVNVTlVTbVo1ZG01RWJWRkdXRXRsYTJjOVBTSXNJblpoYkhWbElqb2lXR2RQY25aa1lqQTRaVkUzWjAxRWVGSnNVRmc1WnowOUlpd2liV0ZqSWpvaVpHUTRNV0kwTkRrME56UmpOekF5TldWbU16QTBNV1V4TURNelpUUmlZbUV4T1RNME9EQTRZMk15T1RGaVlUTmpOMkl6TkdZNU9EUTBOVGd6TmpFNE55SXNJblJoWnlJNklpSjk=" target="_blank">
                                    <img src={`${process.env.BASE_PATH}/img/footer/newCopyRight.png`} alt="curacao"/>
                                </a>
                            </p>
                            <p className="copyright-text">Curaçao Gaming Control Board</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    footerData: state.commonData.cmsFooterImages,
});

const mapDispatchToProps = {
    getCMSFooter: () => getCMSFooter(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
