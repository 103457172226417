import { useState } from "react";

const TITLE_MAP = {
    "/": "Fun88 - การพนันออนไลน์ที่ดีที่สุดในประเทศไทย",
    "/กีฬา/":	"กีฬา",
    "/อีสปอร์ต/":	"อีสปอร์ต",
    "/เกมความเร็ว/":	"เกมความเร็ว",
    "/เกมความเร็ว/spribe/":	"เกมความเร็ว-Spribe",
    "/เกมความเร็ว/galaxsys/":	"เกมความเร็ว-Galaxsys",
    "/คาสิโน/":	"คาสิโน",
    "/คาสิโน/asia-gaming/":	"คาสิโน-Asia Gaming",
    "/คาสิโน/evolution/":	"คาสิโน-Evolution",
    "/คาสิโน/sa-gaming/":	"คาสิโน-Sa Gaming",
    "/คาสิโน/pragmatic-play/":	"คาสิโน-Pragmatic Play",
    "/คาสิโน/wm-casino/":	"คาสิโน-Wm Casino",
    "/คาสิโน/dream-gaming/":	"คาสิโน-Dream Gaming",
    "/เกม-3-มิติ/":	"เกม 3 มิติ",
    "/สล็อต/":	"สล็อต",
    "/สล็อต/pragmatic-play/":	"สล็อต-Pragmatic Play",
    "/สล็อต/pg-soft/":	"สล็อต-Pg Soft",
    "/สล็อต/microgaming/":	"สล็อต-Microgaming",
    "/สล็อต/playtech/":	"สล็อต-Playtech",
    "/สล็อต/betsoft/":	"สล็อต-Betsoft",
    "/สล็อต/spadegaming/":	"สล็อต-Spadegaming",
    "/สล็อต/netent/":	"สล็อต-Netent",
    "/สล็อต/skywind/":	"สล็อต-Skywind",
    "/สล็อต/jili/":	"สล็อต-Jili",
    "/สล็อต/ยิงปลา-jili/":	"สล็อต-ยิงปลา Jili",
    "/สล็อต/habanero/":	"สล็อต-Habanero",
    "/สล็อต/spinix/":	"สล็อต-Spinix",
    "/สล็อต/evoplay/":	"สล็อต-Evoplay",
    "/สล็อต/big-time/":	"สล็อต-Big Time",
    "/สล็อต/red-tiger/":	"สล็อต-Red Tiger",
    "/สล็อต/hacksaw/":	"สล็อต-Hacksaw",
    "/สล็อต/booongo/":	"สล็อต-Booongo",
    "/หวย/":	"หวย",
    "/หวย/slc/":	"หวย-Slc",
    "/โปรโมชั่น/":	"โปรโมชั่น",
    "/ดาวน์โหลด-fun88/":	"ดาวน์โหลด Fun88",
    "/คาสิโน/sexy-gaming/":	"คาสิโน-Sexy Gaming",
    "/คาสิโน/gpi/":	"คาสิโน-Gpi",
    "/เกม-3-มิติ/kingmidas/":	"เกม 3 มิติ-Kingmidas",
    "/เกม-3-มิติ/kingspoker/":	"เกม 3 มิติ-Kingspoker",
    "/เกม-3-มิติ/joker-gaming/":	"เกม 3 มิติ-Joker Gaming",
    "/สล็อต/cq9-gaming/":	"สล็อต-Cq9 Gaming",
    "/สล็อต/joker/":	"สล็อต-Joker",
    "/สล็อต/play-n-go/":	"สล็อต-Play N Go",
    "/สล็อต/naga-games/":	"สล็อต-Naga Games",
    "/สล็อต/ask-me-bet/":	"สล็อต-Ask Me Bet",
    "/สล็อต/fachai/":	"สล็อต-Fachai",
    "/หวย/tc-gaming/":	"หวย-Tc Gaming",
    "/สล็อต/fishing-games/":	"สล็อต-Fishing Games",
    "/สล็อต/promotion-games/":	"สล็อต-Promotion Games",
    "/สล็อต/hot-games/":	"สล็อต-Hot Games",
    "/สล็อต/arcade-games/":	"สล็อต-Arcade Games",
    "/สล็อต/easy-to-win/":	"สล็อต-Easy To Win",
    "/สล็อต/other-games/":	"สล็อต-Other Games",
    "/สล็อต/all-games/":	"สล็อต-All Games",
    "/สล็อต/jackpot/":	"สล็อต-Jackpot",
    "/เกม-3-มิติ/poker/":	"เกม 3 มิติ-Poker",
    "/เกม-3-มิติ/sicbo/":	"เกม 3 มิติ-Sicbo",
    "/เกม-3-มิติ/dragon-tiger/":	"เกม 3 มิติ-Dragon Tiger",
    "/เกม-3-มิติ/hot-games/":	"เกม 3 มิติ-Hot Games",
    "/เกม-3-มิติ/other-games/":	"เกม 3 มิติ-Other Games",
    "/เกม-3-มิติ/all-games/":	"เกม 3 มิติ-All Games",
    "/หวย/fast-lottery/":	"หวย-Fast Lottery",
    "/หวย/government-lottery/":	"หวย-Government Lottery",
    "/หวย/all-games/":	"หวย-All Games",
    "/เกมความเร็ว/hot-games/":	"เกมความเร็ว-Hot Games",
    "/เกมความเร็ว/crash-games/":	"เกมความเร็ว-Crash Games",
    "/เกมความเร็ว/plinko/":	"เกมความเร็ว-Plinko",
    "/เกมความเร็ว/keno/":	"เกมความเร็ว-Keno",
    "/เกมความเร็ว/all-games/":	"เกมความเร็ว-All Games",
    "/คาสิโน/baccarat/":	"คาสิโน-Baccarat",
    "/คาสิโน/sicbo/":	"คาสิโน-Sicbo",
    "/คาสิโน/roulette/":	"คาสิโน-Roulette",
    "/คาสิโน/dragon-tiger/":	"คาสิโน-Dragon Tiger",
    "/คาสิโน/other-games/":	"คาสิโน-Other Games",
    "/คาสิโน/all-games/":	"คาสิโน-All Games",
    "/คาสิโน/wec/":	"คาสิโน-WE Casino",
    "/หวย/gpk/":	"หวย-Game Play Interactive Keno",
}

export default function seoContainer({ seocontent }) {
    const [readMore, setReadMore] = useState(true);

    // Get current path and create mapping function
    const getCurrentTitle = () => {
        // Get path after domain
        const path = typeof window !== 'undefined' ? window.location.pathname : "/";
        
        // Remove leading '/th' and query parameters, but keep trailing slash
        const normalizedPath = decodeURIComponent(path)
            .replace(/^\/th/, '')        // Remove leading '/th'
            .split('?')[0];              // Remove everything after '?'
        
        return TITLE_MAP[normalizedPath];
    };

    return (
        <div className="common-distance">
            <div
                className={`footer-description-wrap${
                    readMore ? " active" : ""
                }`}
            >
                <div className="footer-header">
                    <div className="footer-header-title">{getCurrentTitle()}</div>
                    <div 
                        className="expand-button"
                        onClick={() => {
                            setReadMore(!readMore);

                            // After state is updated, check if content is collapsed
                            if (readMore) {
                                // Find the footer description element and scroll to it
                                const footerElement = document.querySelector('.footer-content-body');
                                if (footerElement) {
                                    // Scroll the content to top
                                    footerElement.scrollTop = 0;
                                }
                            }
                        }}
                    >
                        <img
                            className={readMore ? '' : 'collapse'}
                            src={
                                "/th/img/icon/expand.svg"
                            }
                            alt="expand"
                        />
                    </div>
                </div>
                <div
                    className={`footer-content-body${
                        readMore ? " active" : ""
                    }`}
                    dangerouslySetInnerHTML={{ __html: seocontent }}
                />
            </div>
        </div>
    );
}