/*
 * @Author: Alan
 * @Date: 2023-06-02 18:21:35
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-01 09:16:25
 * @Description: 游戏相关数据
 * @FilePath: /F1-M2-WEB-Code/store/thunk/gameThunk.js
 */
import {
    GetCategoryList,
    GetFlashProviderList,
    GetSmarticoToggle,
} from "$ACTIONS/gameRequest";
import { gameActions } from "$STORE/gameSlice";
import { DEFAULT_AFFILIATE_URL, mainSiteConfigActions } from "$STORE/mainSiteConfigSlice";
import { store } from "$STORE/store";
const { gamesToFilter } = store.getState().game;
/**
 * @description: 获取CGMS-Api 导航相关資料"
 * @param {*} selectedCategory
 * @param {*} stopLoading
 * @return {*}
 */
export function getGameCategoryListAction(selectedCategory, stopLoading) {
    return async function getGameListThunk(dispatch, getState) {
        dispatch(gameActions.setLoading(true));
        try {
            const GameList = await GetCategoryList();
            if (!GameList) {
                dispatch(gameActions.setGameCategoriesData([]));
                return;
            }
            dispatch(gameActions.setGameCategoriesData(GameList));
            dispatch(gameActions.setLoading(false));
        } catch (e) {
            console.error("thunk getGameList error", e.message);
        } finally {
            stopLoading && stopLoading();
        }
    };
}

/**
 * @description: 获取 FlashProvider,目的是为了获得平台列表
 * @param {*} categoryType 游戏种类
 * @param {*} gameCatId 游戏种类ID
 * @return {*}
 */
export function getFlashProviderListAction(categoryType, isShowFishingGames) {
    return async function (dispatch, getState) {
        try {
            const providerData = await GetFlashProviderList(categoryType, isShowFishingGames);

            const filteredData = providerData.filter(
                (provider) => !gamesToFilter.includes(provider.providerCode)
            );
            dispatch(
                gameActions[`set_${categoryType}_ProviderData`](
                    filteredData
                )
            );
        } catch (error) {
            console.error(error.message);
        }
    };
}

/**
 * @description: 设置隐藏游戏
 * @param {*} gameCatId 游戏种类
 * @return {*}
 */
export function setGameHideAction(gameCat) {
    return async function updateGameHideThunk(dispatch, getState) {
        await dispatch(gameActions.updateGameHide(gameCat));
    };
}

/**
 * @description: 重置游戏隐藏
 * @param {*} gameCatId 游戏种类
 * @return {*}
 */
export function setResetGameHideAction() {
    return function updateResetGameHideThunk(dispatch, getState) {
        dispatch(gameActions.resetData());
    };
}

/**
 * @description: smartico toggle
 * @return {*}
 */
export function getSmarticoToggleAction() {
    return async function getSmarticoToggleThunk(dispatch, getState) {
        try {
            const res = await GetSmarticoToggle();
            dispatch(
                gameActions.setSmarticoVisible(
                    res?.result?.smarticoIsActive || false
                )
            );
            dispatch(
                mainSiteConfigActions.setAffiliateUrl(
                    res?.result?.affiliateUrl || DEFAULT_AFFILIATE_URL
                )
            );
        } catch (e) {
            dispatch(gameActions.setSmarticoVisible(false));
            console.error(e.message);
        }
    };
}

/**
 * @description: Smartico Modal
 */
export function setSmarticoErrorModalAction(flag, back) {
    return function smarticoErrorModalActionThunk(dispatch, getState) {
        const currentState = getState().game.smarticoMaintenanceModal;

        dispatch(
            gameActions.setSmarticoErrorModalVisible({
                visible: flag,
                backHomePage: back !== undefined ? back : currentState.backHomePage,
            })
        );
    };
}

/**
 * @description: 紀錄Aviator game id (only for generate <a> href)
 * @param {number} aviatorId aviator game id
 */
export const setAviatorGameId = (aviatorId) => {
    return function setAviatorGameIdThunk(dispatch, getState) {
        dispatch(gameActions.setAviatorGameId(aviatorId));
    };
};

