/*
 * @Author: Alan
 * @Date: 2022-06-07 07:47:26
 * @LastEditors: Alan
 * @LastEditTime: 2022-09-18 19:23:29
 * @Description:游戏标签
 * @FilePath: \Mobile\src\components\Games\Tag.js
 */
import React, { Component } from "react";
import classNames from "classnames";
class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMore: true,
        };
    }

    componentDidMount() {}

    getStyle = (provider, gameType = "") => {
        switch (provider) {
            /* Slot */
            case "SPX":
                return { backgroundColor: "#171717", color: "#F8AA1A" };
            case "AMB":
                return { backgroundColor: "#309246" };
            case "JIR":
                return { backgroundColor: "#2230FF" };
            case "JIF":
                return { backgroundColor: "#FFFFFF", color: "#2230FF" };
            case "JKR":
                return { backgroundColor: "#E0AE02" };
            case "PGS":
                return { backgroundColor: "#12B2A1" };
            case "MGSQF":
            case "MGP":
                return { backgroundColor: "#2ACA8E" };
            case "PNG":
                return { backgroundColor: "#0182C3" };
            case "FISHING":
                return { backgroundColor: "#00A6FF" };
            case "TG_SLOT":
                return { backgroundColor: "#F49239" };
            case "IMONET":
                return { backgroundColor: "#FFFFFF", color: "#46BF00" };
            case "HBN":
                return { backgroundColor: "#FB5D42" };
            case "NGS":
                return { backgroundColor: "#613AB2" };
            case "BNG":
                return { backgroundColor: "#19E4A4" };
            case "EVP":
                return { backgroundColor: "#1E5F95" };
            case "SWF":
                return { backgroundColor: "#6978DF" };
            case "CQG":
                return { backgroundColor: "#FFFFFF", color: "#FF7700" };
            case "TG":
                switch (gameType) {
                    case "Slot":
                        return { backgroundColor: "#F49239" };
                    case "LiveCasino":
                    default:
                        return { backgroundColor: "#FFFFFF", color: "#F08B08" };
                }
            case "SPG":
                return { backgroundColor: "#F30000" };
            case "BSG":
                return { backgroundColor: "#000000" };
            case "HSW":
                return { backgroundColor: "#5FB174" };
            case "EVOBT":
                return { backgroundColor: "#171717", color: "#FF0000" };
            case "EVORT":
                return { backgroundColor: "#D9E2DB", color: "#FF0000" };
            case "FCG":
                return { backgroundColor: "#2828B2", color: "#FF8519" };
            /* 棋牌 */
            case "JBP":
                return { backgroundColor: "#D1941B" };
            case "KYG":
                return { backgroundColor: "#E96450" };
            case "TGP":
                return { backgroundColor: "#F09A01" };
            case "KPK":
                return { backgroundColor: "#047B6C" };
            /* 真人娱乐 */
            case "YBL":
                return { backgroundColor: "#E4D3B2", color: "#3D3C39" };
            case "EVO":
                return { backgroundColor: "#679DB9" };
            case "BGG":
                return { backgroundColor: "#311EB9" };
            case "AGL":
            case "AG":
                return { backgroundColor: "#F98436" };
            case "WEC":
                return { backgroundColor: "#A88241" };
            case "DGG":
                return { backgroundColor: "#EBEBEB", color: "#9F8247" };
            case "NLE":
                return { backgroundColor: "#FFFFFF", color: "#14594E" };
            case "EBT":
                return { backgroundColor: "#2E67B1" };
            case "ABT":
                return { backgroundColor: "#212121", color: "#D1C5AD" };
            case "VTG":
                return { backgroundColor: "#009A23" };
            case "WMC":
                return { backgroundColor: "#0E1544" };
            case "SAL":
                return { backgroundColor: "#14594E" };
            case "SXY":
                return { backgroundColor: "#FD3684" };
            /* 彩票 */
            case "SLS":
                return { backgroundColor: "#44B1D5" };
            case "TCG":
                return { backgroundColor: "#A40000" };
            case "GPK":
                return { backgroundColor: "#1495DA" };
            /* InstantGames */
            case "SPR":
                return { backgroundColor: "#E5073A" };
            case "GLX":
                return { backgroundColor: "#0668FF" };
            default:
                return { backgroundColor: "#00A6FF" };
        }
    };
    // 暫時 hardCode 處理文字，目前 CXFUN88-5230 on hold
    getName = (provider, gameType = "") => {
        switch (provider) {
            // Sportbooks
            case "VTG":
                return "VTG";
            //  Slot
            case "SPX":
                return "SPX";
            case "AMB":
                return "AMB";
            case "JKR":
                switch (gameType) {
                    case "Slot":
                        return "JK";
                    case "P2P":
                    default:
                        return "JKR";
                }
            case "PGS":
                return "PG";
            case "MGSQF":
            case "MGP":
                return "MG";
            case "PNG":
                return "PNG";
            case "TG_SLOT":
                return "PP";
            case "IMONET":
                return "NET";
            case "SWF":
                return "SW";
            case "CQG":
                return "CG9";
            case "SPG":
                return "SG";
            case "BSG":
                return "BSG";
            case "FISHING":
                return "FISHING";
            case "TG":
                return "PP";
            case "HSW":
                return "HSW";
            case "EVOBT":
                return "BTG";
            case "EVORT":
                return "RT";
            /* 棋牌 */
            case "TGP":
                return "TGP";
            case "JBP":
                return "JBP";
            case "KYG":
                return "KYG";
            /* 真人娱乐 */
            case "WEC":
                return "WE";
            case "YBL":
                return "ZUI";
            case "EVO":
                return "EVO";
            case "BGG":
                return "BG";
            case "AGL":
            case "AG":
                return "AG";
            case "GPI":
                return "FUN";
            case "DGG":
                return "DG";
            case "NLE":
                return "N2";
            case "EBT":
                return "EBT";
            case "ABT":
                return "ABT";
            case "WMC":
                return "WMC";
            case "SAL":
                return "SA";
            case "SXY":
                return "SXY";
            /* 彩票 */
            case "SLS":
                return "SLC"
            case "TCG":
                return "TC";
            case "GPK":
                return "FUN";
            case "SLS":
                return "SLC";
            default:
                return provider;
        }
    };
    render() {
        const { provider, providerFlag, gameCatCode } = this.props;
        return (
            <div
                className={classNames({
                    DefaultTag: true,
                })}
            >
                <span style={this.getStyle(provider, gameCatCode)}>
                    {this.getName(provider, gameCatCode)}
                </span>
            </div>
        );
    }
}
export default Tag;
