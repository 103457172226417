import { ApiPort } from "$ACTIONS/TLCAPI";
import { advget } from "$ACTIONS/TlcRequest";
import { message } from "antd";
import { balanceActions } from "$STORE/balanceSlice";
import { enumWalletProductGroupId } from "$DATA/balance.static";

export const getBalanceInfoAction = () => async (dispatch) => {
    try {
        dispatch(balanceActions.setBalanceLoading(true));

        // 同步呼叫兩個 API (/api/Balance/v2.0, /api/Balance/WalletGroup)
        const [balanceRes, groupDataRes] = await Promise.all([
            advget(ApiPort.GETBalance),
            advget(ApiPort.GETWalletProductGroups),
        ]);

        // 確保兩個 API 的資料都成功取得
        if (
            balanceRes?.isSuccess &&
            balanceRes?.result &&
            groupDataRes?.isSuccess &&
            groupDataRes?.result
        ) {
            // 建立 mapping 表
            const groupCodeMap = groupDataRes.result.reduce((acc, item) => {
                acc[item.walletProductGroupId] = item.walletProductGroupCode;
                return acc;
            }, {});

            // 覆蓋主錢包資料
            const updatedBalances = balanceRes.result.balances.map((item) => {
                if (item.walletProductGroupId === enumWalletProductGroupId.MAIN.ID) {
                    return {
                        ...item,
                        walletProductGroupId: 0, //自定義TotalBal groupId = 0 以免混淆
                        walletProductGroupName: "ยอดเงินรวม",
                        walletProductGroupCode: "TOTAL",
                        balance: balanceRes.result.totalBalance,
                        usableAmount: balanceRes.result.withdrawableBalance,
                        lockedBalance: balanceRes.result.totalContractBalance,
                    };
                }
                return {
                    ...item,
                    walletProductGroupCode:
                        groupCodeMap[item.walletProductGroupId] || null,
                };
            });

            /**
             * MAIN 錢包目前不可以加在 balances 裡面，
             * 會導致用 balances render 的選單多出不必要的Main選項
             * 因此在這邊獨立處理
             */ 
            const mainWalletItem = balanceRes.result.balances.find(balance => balance.walletProductGroupId === enumWalletProductGroupId.MAIN.ID);
            const MAIN = {
                balance: mainWalletItem.balance,
                lockedBalance: mainWalletItem.lockedBalance,
                usableAmount: mainWalletItem.usableAmount
            };

            // 更新 balanceInfo
            const updatedBalanceInfo = {
                ...balanceRes.result,
                balances: updatedBalances,
                MAIN,
            };

            dispatch(balanceActions.setBalanceInfo(updatedBalanceInfo));
        } else {
            // 處理失敗訊息
            const errorMessage =
                balanceRes?.errors?.[0]?.description ||
                groupDataRes?.errors?.[0]?.description ||
                "Failed to fetch balance information.";
            message.error(errorMessage);
        }
    } catch (error) {
        console.error("Failed to fetch balance data", error);
    } finally {
        dispatch(balanceActions.setBalanceLoading(false));
    }
};
