import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const defaultTop = 200; // 默认的 top 位置
const defaultDuration = 3; // 默认的显示时间 (秒)

// 定义默认图标
const defaultIcons = {
    success: () => <img src="/th/img/icon/success.svg" alt="success" />,
    error: () => <img src="/th/img/icon/error.svg" alt="error" />,
    info: () => <img src="/th/img/icon/yellow-info.svg" alt="info" />,

    // 目前未使用 `warning` & `loading`，如需使用請自行調整 icon
    warning: () => <img src="/th/img/icon/yellow-info.svg" alt="warning" />,
    loading: () => <LoadingOutlined style={{ color: "#1890ff" }} />,
};

const showMessage = (
    type,
    content,
    top = defaultTop,
    duration = defaultDuration,
    customIcon = null
) => {
    // 动态更新 top（如果传入了不同的 top）
    if (top !== defaultTop) {
        message.config({ top });
    }

    message.open({
        type,
        content,
        duration,
        icon: customIcon || (defaultIcons[type] ? defaultIcons[type]() : null), // 使用自定义图标或默认图标
    });

    // 恢复默认 top 位置（如果 top 被修改）
    if (top !== defaultTop) {
        message.config({ top: defaultTop });
    }
};

export default showMessage;
