import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover, Spin } from "antd";
import { getBalanceInfoAction } from "$STORE/thunk/balanceThunk";
import { bonusActions } from "$STORE/bonusSlice";
import { numberformatter } from "$ACTIONS/util";

const HoverBalanceDisplay = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("TOTAL"); // 預設選中類別

    const dispatch = useDispatch();
    const { balanceInfo, isBalanceLoading } = useSelector(
        (state) => state.balance
    )

    const { balances = [], isWithdrawalContractBalanceBlocking = false } =
        balanceInfo || {};

    // 取得當前選中的類別資料
    const selectedData = useMemo(() => {
        return (
            balances.find(
                (balance) => balance.walletProductGroupCode === selectedCategory
            ) || {
                balance: 0,
                usableAmount: 0,
                lockedBalance: 0,
                walletProductGroupName: "",
            }
        );
    }, [balances, selectedCategory]);

    const { balance, usableAmount, lockedBalance, walletProductGroupName } =
        selectedData;

    // 判斷是否為 TOTAL 類別
    const isTotalCategory = selectedCategory === "TOTAL";

    // 處理選擇類別變更
    const handleCategoryChange = (option) => {
        const { walletProductGroupCode } = option;
        Pushgtagdata(
            "NavBar",
            "Choose Balance Type",
            "NavBar_BalanceType_C_Choose",
            false,
            [
                {
                    customVariableKey: `NavBar_BalanceType_C_Choose`,
                    customVariableValue: walletProductGroupCode,
                },
            ]
        );
        setIsOpen(false);
        setSelectedCategory(walletProductGroupCode);
    };

    const handleRefresh = () => {
        Pushgtagdata("NavBar", "Refresh Balance", "NavBar_C_RefreshBalance");
        dispatch(getBalanceInfoAction());
    };

    return (
        <>
            <Spin spinning={isBalanceLoading}>
                <div className="header-popover-inner-title">
                    <div className="custom-dropdown">
                        {/* 選擇框 */}
                        <div
                            className="dropdown-header"
                            onMouseEnter={() => setIsOpen(true)}
                            onMouseLeave={() => setIsOpen(false)}
                            onClick={() => {
                                Pushgtagdata(
                                    "NavBar",
                                    "Click Balance Type",
                                    "NavBar_C_BalanceType"
                                );
                            }}
                        >
                            {walletProductGroupName}
                            <div
                                className={`tlc-sprite dropdown-arrow ${
                                    isOpen ? "open" : ""
                                }`}
                            />
                            {/* 下拉選單 */}
                            {isOpen &&
                                Array.isArray(balances) &&
                                balances.length > 0 && (
                                    <div className="dropdown-menu-container">
                                        <div className="dropdown-space" />
                                        <div className="dropdown-menu">
                                            {balances.map((option) => (
                                                <div
                                                    key={
                                                        option.walletProductGroupId
                                                    }
                                                    className={`dropdown-item ${
                                                        selectedCategory ===
                                                        option.walletProductGroupCode
                                                            ? "selected"
                                                            : ""
                                                    }`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleCategoryChange(
                                                            option
                                                        );
                                                    }}
                                                >
                                                    {
                                                        option.walletProductGroupName
                                                    }
                                                    {selectedCategory ===
                                                        option.walletProductGroupCode && (
                                                        <span className="check-icon" />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className="header-popover-inner-content">
                    {/* Total Balance */}
                    <div className="balance-total-container">
                        <div className="balance-total">
                            ฿ {numberformatter(balance, 2, "removeWhenDecimalAllZero")}
                        </div>
                        <div className="refresh-icon" onClick={handleRefresh}>
                            <img src="/th/img/icon/refresh.svg" alt="refresh" />
                        </div>
                    </div>

                    {/* Balance Details，toggle off 時 TOTAL 不顯示 Details */}
                    {!(
                        selectedCategory === "TOTAL" &&
                        !isWithdrawalContractBalanceBlocking
                    ) && (
                        <div className="balance-details">
                            {/* Withdrawable Balance */}
                            <div className="balance-row">
                                <div className="balance-info">
                                    {isTotalCategory
                                        ? "ยอดเงินที่ถอนได้"
                                        : "ยอดเงินที่ใช้ได้"}
                                    <Popover
                                        placement="right"
                                        content={
                                            isTotalCategory ? (
                                                <>
                                                    ยอดหมุนเวียนการถอนจะอัปเดตภายใน
                                                    15 นาที
                                                    <br />
                                                    หลังจากการเดิมพันได้รับการตัดสิน
                                                </>
                                            ) : (
                                                <>
                                                    สามารถใช้เดิมพันกับเกมประเภทใดก็ได้
                                                </>
                                            )
                                        }
                                        onClick={() => {
                                            Pushgtagdata(
                                                "NavBar",
                                                "View Balance Tooltip",
                                                "NavBar_C_BalanceToolTip"
                                            );
                                        }}
                                    >
                                        <img
                                            src="/th/img/icon/info.svg"
                                            alt="info"
                                            className="info"
                                        />
                                    </Popover>
                                </div>
                                <div className="balance-value">
                                    ฿ {numberformatter(usableAmount, 2, "removeWhenDecimalAllZero")}
                                </div>
                            </div>

                            {/* Locked Balance */}
                            <div className="balance-row">
                                <div className="balance-info">
                                    {isTotalCategory ? (
                                        <>
                                            ยอดหมุนเวียน
                                            <br />
                                            ที่ยังไม่ครบ
                                        </>
                                    ) : (
                                        <>
                                            ยอดเงินโบนัส
                                            <br />
                                            ที่ถูกล็อก
                                        </>
                                    )}
                                    <Popover
                                        placement="right"
                                        content={
                                            isTotalCategory ? (
                                                <>
                                                    ทำยอดหมุนเวียนให้ครบตามที่กำหนดเพื่อ
                                                    <br />
                                                    ปลดล็อกยอดเงินฝากและโบนัส
                                                    <br />
                                                    เพื่อสามารถทำการถอนออกได้
                                                </>
                                            ) : (
                                                <>
                                                    จำนวนเงินที่ถูกล็อคจากการสมัครโปรโมชั่น
                                                    <br />
                                                    สามารถใช้ได้เฉพาะเกมในหมวดหมู่นี้เท่านั้น
                                                </>
                                            )
                                        }
                                        onClick={() => {
                                            Pushgtagdata(
                                                "NavBar",
                                                "View Locked Balance Tooltip",
                                                "NavBar_C_LockedBalanceTooltip"
                                            );
                                        }}
                                    >
                                        <img
                                            src="/th/img/icon/lock.svg"
                                            alt="lock"
                                            className="lock"
                                        />
                                    </Popover>
                                </div>
                                <div className="balance-value">
                                    ฿ {numberformatter(lockedBalance, 2, "removeWhenDecimalAllZero")}
                                </div>
                                {isWithdrawalContractBalanceBlocking && (
                                    <img
                                        src="/th/img/icon/black-arrow.svg"
                                        alt="arrow"
                                        className="right-arrow"
                                        onClick={() => {
                                            Pushgtagdata(
                                                "NavBar",
                                                "View Locked Balance Detail",
                                                "NavBar_C_LockedBalance"
                                            );
                                            dispatch(bonusActions.setLockedBalanceVisible(true));
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Spin>
        </>
    );
};

export default HoverBalanceDisplay;
