import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBonusProgressAction } from "$STORE/thunk/bonusThunk";
import moment from "moment";
import { Modal, Pagination, Icon, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { numberformatter } from "$ACTIONS/util";
import Newbie from "@/Newbie";

const LockedBalance = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const {
        totalContractBalance = 0,
        totalDepositContractBalance = 0,
        lastDepositContractUpdatedDate = null,
        contract = [],
    } = useSelector((state) => state.bonus.progressDetails || {});

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        if (visible) {
            dispatch(getBonusProgressAction());
            global.Pushgtagpiwikurl &&
                global.Pushgtagpiwikurl("locked_balance", "Locked Balance");
        }
    }, [visible, dispatch]);

    /* -- fake data --
        const apiData = {
            result: {
                totalContractBalance: 539.62,
                totalDepositContractBalance: 498.62,
                lastDepositContractUpdatedDate: "2024-12-25T10:35:01.181Z",
                contract: [
                    {
                        id: 536087,
                        contractCategory: "ADJUSTMENT",
                        walletProductGroupId: 1,
                        walletProductGroupName: "余额",
                        applyDateTime: "2024-11-07T08:07:29.539Z",
                        initialBalance: 5,
                        contractBalance: 5,
                        requiredTurnover: 5,
                        currentTurnover: 0,
                        turnoverProgress: 0,
                    },
                    {
                        id: 536740,
                        title: "真人-普通-预付红利1456465468797真人-普通-预付红利1",
                        contractCategory: "PREBONUS",
                        walletProductGroupId: 4,
                        walletProductGroupName: "真人",
                        applyDateTime: "2024-12-24T09:10:53.46Z",
                        expiredDateTime: "2025-01-23T09:10:31.798Z",
                        initialBalance: 24,
                        contractBalance: 36,
                        requiredTurnover: 36,
                        currentTurnover: 0,
                        turnoverProgress: 0,
                    },
                ],
            },
            isSuccess: true,
        };

        const generateFakeContracts = (template, count) => {
            return Array.from({ length: count }, (_, index) => ({
            ...template,
            id: template.id + index, // 保证每条记录的 ID 唯一
            applyDateTime: new Date(
                new Date(template.applyDateTime).getTime() + index * 1000 * 60 * 60 * 24
            ).toISOString(), // 每条记录的时间增加一天
            contractBalance: template.contractBalance + index * 10, // 动态修改 contractBalance
            requiredTurnover: template.requiredTurnover + index * 5, // 动态修改 requiredTurnover
            }));
        };
        
        const fakeContracts = [
            ...generateFakeContracts(apiData.result.contract[0], 50), // 基于第一条记录生成 50 条
            ...generateFakeContracts(apiData.result.contract[1], 50), // 基于第二条记录生成 50 条
        ];
        
        // 更新 apiData
        const apiDataWithMoreContracts = {
            ...apiData,
            result: {
            ...apiData.result,
            contract: fakeContracts,
            },
        };

        const { result } = apiDataWithMoreContracts;
        const {
            totalContractBalance,
            totalDepositContractBalance,
            lastDepositContractUpdatedDate,
            contract,
        } = result || {
            totalContractBalance: 0,
            totalDepositContractBalance: 0,
            lastDepositContractUpdatedDate: null,
            contract: [],
        };
    */
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentContracts = contract.slice(
        startIndex,
        startIndex + itemsPerPage
    );

    const getCategoryDisplay = (contractItem) => {
        const category = contractItem?.contractCategory?.toUpperCase();
        if (category === "ADJUSTMENT") return "การปรับยอดโบนัสโดยระบบ"; //系統派發
        if (category === "REFERRAL") return "แนะนำเพื่อน"; //推薦好友
        if (category === "REBATE") return "โบนัสเงินคืน"; //返水
        if (category === "REWARDPOINT") return "แลกเงินเดิมพันฟรีจากแต้ม" //樂幣兌換彩金
        return contractItem?.title;
    };

    const renderProgress = (completed, total, progress) => {
        return (
            <div className="progress-wrapper">
                <span className="progress-description">
                    {/* 流水进度(完成流水进度才可解锁至可提款金额) */}
                    <span>ยอดหมุนเวียนสะสม</span>
                    <div className="progress-note">
                        (เมื่อทำยอดหมุนเวียนครบตามที่กำหนด
                        <br />
                        คุณจะสามารถถอนเงินออกได้)
                    </div>
                </span>
                <div className="progress-text">
                    <div className="progress-completed">{numberformatter(completed, 0)}</div>
                    <div className="progress-separator">/</div>
                    <div className="progress-total">{numberformatter(total, 0)}</div>
                    <div className="progress-percentage">{numberformatter(progress, 0)}%</div>
                </div>
                <div className="progress-bar-background">
                    <div
                        className={`progress-bar-fill`}
                        style={{ width: `${numberformatter(progress, 0)}%` }}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal
                className="locked-balance-modal"
                title={
                    <div>
                        {/* 未完成流水金额 */}
                        <h2 className="modal-title">ยอดหมุนเวียนที่ยังไม่ครบ</h2>
                        <p className="modal-description">
                            {/* 未完成流水总金额 */}
                            <span className="modal-description-title">
                                ยอดเงินที่ถูกล็อกทั้งหมด
                            </span>
                            {/* 完成流水要求，以解锁符合条件的存款和优惠金额至可提款金额 */}
                            ทำยอดหมุนเวียนให้ครบตามที่กำหนดเพื่อปลดล็อกยอดเงินฝากและโบนัส
                            เพื่อสามารถทำการถอนออกได้
                        </p>
                        <p className="modal-amount">฿ {numberformatter(totalContractBalance, 2, "removeWhenDecimalAllZero")}</p>
                    </div>
                }
                visible={visible}
                onCancel={onClose}
                footer={null}
                width={900}
                maskClosable={false}
                closeIcon={
                    <img
                        src={"/th/img/icon/icon-close.svg"}
                        style={{ width: "16px", height: "16px" }}
                        alt="close"
                    />
                }
            >
                <div className="locked-balance-container">
                    <div className="locked-details">
                        <h4 className="locked-description">
                            {/* 存款锁定 */}
                            <span className="locked-description-title">
                                ยอดฝากถูกล็อค
                            </span>
                            {/* 完成一倍流水即可解锁至可提款金额 */}
                            ทำยอดหมุนเวียน 1 เท่า เพื่อ "ปลดล็อค"
                            จำนวนเงินที่สามารถถอนออกได้
                        </h4>
                        <p className="locked-amount">
                            <img
                                src="/th/img/icon/lock.svg"
                                alt="lock"
                                className="lock"
                            />
                            <span>฿ {numberformatter(totalDepositContractBalance, 2, "removeWhenDecimalAllZero")}</span>
                        </p>
                        {lastDepositContractUpdatedDate && (
                            <p className="locked-update-time">
                                เวลาที่อัปเดตล่าสุด{" "}
                                {moment(lastDepositContractUpdatedDate)
                                    .utcOffset(8)
                                    .format("DD/MM/YYYY HH:mm")}
                            </p>
                        )}
                    </div>
                    <div className="locked-details-table">
                        <div className="bonus-locked">
                            {/* 优惠锁定 */}
                            <h4 className="bonus-title">โบนัสถูกล็อค</h4>
                            <img
                                src="/th/img/icon/lock.svg"
                                alt="lock"
                                className="lock"
                            />
                            <p className="bonus-amount">฿ {numberformatter(totalContractBalance - totalDepositContractBalance, 2, "removeWhenDecimalAllZero")}</p>
                        </div>
                        <table className="bonuses-table">
                            <thead>
                                <tr>
                                    {/* 游戏类别 */}
                                    <th className="table-header">หมวดหมู่เกม</th>
                                    {/* 优惠名称 */}
                                    <th className="table-header">ชื่อโบนัส</th>
                                    {/* 派发时间 */}
                                    <th className="table-header">
                                        เวลาปรับยอด
                                        <br />
                                        <span className="gmt-label">(GMT+8)</span>
                                    </th>
                                    {/* 彩金金额 */}
                                    <th className="table-header">ยอดโบนัส</th>
                                    {/* 未完成流水金额 */}
                                    <th className="table-header">
                                        ยอดหมุนเวียน
                                        <br />
                                        ที่ยังไม่ครบ
                                    </th>
                                    {/* 进度 */}
                                    <th className="table-header">ความคืบหน้า</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(currentContracts) &&
                                currentContracts?.length > 0 ? (
                                    currentContracts.map((contractItem, index) => (
                                        <tr key={index} className="table-row">
                                            <td className="table-cell no-border">
                                                {
                                                    contractItem?.walletProductGroupName
                                                }
                                            </td>
                                            <td className="table-cell no-border">
                                                <Popover
                                                    content={getCategoryDisplay(
                                                        contractItem
                                                    )}
                                                    placement="bottom"
                                                    overlayStyle={{ maxWidth: "180px" }} // 设置内容的最大宽度
                                                >
                                                    <div className="bonus-name">
                                                        {getCategoryDisplay(
                                                            contractItem
                                                        )}
                                                    </div>
                                                </Popover>
                                            </td>
                                            <td className="table-cell no-border">
                                                <div className="date-time-container">
                                                    {contractItem?.applyDateTime ? (
                                                        <>
                                                            {moment(
                                                                contractItem.applyDateTime
                                                            )
                                                                .utcOffset(8)
                                                                .format("DD/MM/YYYY")}
                                                            <br />
                                                            {moment(
                                                                contractItem.applyDateTime
                                                            )
                                                                .utcOffset(8)
                                                                .format("HH:mm")}
                                                        </>
                                                    ) : (
                                                        "--"
                                                    )}
                                                    {contractItem?.expiredDateTime && (
                                                        <Popover
                                                            content={`เวลาสิ้นสุด: ${moment(
                                                                contractItem.expiredDateTime
                                                            )
                                                                .utcOffset(8)
                                                                .format(
                                                                    "DD/MM/YYYY HH:mm"
                                                                )}`}
                                                            placement="bottom"
                                                        >
                                                            <InfoCircleOutlined style={{ fontSize: "16px" }}/>
                                                        </Popover>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="table-cell no-border">
                                                ฿ {numberformatter(contractItem?.initialBalance, 2, "removeWhenDecimalAllZero")}
                                            </td>
                                            <td className="table-cell no-border">
                                                ฿ {numberformatter(contractItem?.contractBalance, 2, "removeWhenDecimalAllZero")}
                                            </td>
                                            <td className="table-cell no-border">
                                                {renderProgress(
                                                    contractItem?.currentTurnover,
                                                    contractItem?.requiredTurnover,
                                                    contractItem?.turnoverProgress
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {/* 目前没有优惠记录 */}
                                        <td colSpan="6" className="no-records">
                                            ขณะนี้ไม่มีบันทึกโบนัส
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {Array.isArray(contract) &&
                            contract?.length > itemsPerPage && (
                                <Pagination
                                    className="custom-pagination"
                                    current={currentPage}
                                    total={contract.length}
                                    pageSize={itemsPerPage}
                                    onChange={handlePageChange}
                                    itemRender={(page, type, originalElement) => {
                                        if (type === "prev") {
                                            return (
                                                <img
                                                    src="/th/img/icon/gray-arrow.svg"
                                                    alt="left arrow"
                                                    style={{ transform: "rotate(-90deg)" }} // 左箭头
                                                />
                                            );
                                        }
                                        if (type === "next") {
                                            return (
                                                <img
                                                    src="/th/img/icon/gray-arrow.svg"
                                                    alt="right arrow"
                                                    style={{ transform: "rotate(90deg)" }} // 右箭头
                                                />
                                            );
                                        }
                                        return originalElement;
                                    }}
                                />
                            )}
                    </div>
                </div>
            </Modal>
            <Newbie
                imgsrc="/th/img/newbie/LockBalance.png"
                imgstyle={{
                    width: 900,
                    marginLeft: -450,
                    top: 30,
                    left: "50%",
                }}
                btnstyle={{ top: 638 }}
            />
        </>
    );
};

export default LockedBalance;
