import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import { bonusActions } from "$STORE/bonusSlice";

export const getBonusProgressAction = () => async (dispatch) => {
    try {
        const res = await get(ApiPort.GETBonusProgress);
        if (res?.isSuccess && res?.result) {
            dispatch(bonusActions.setBonusProgress(res.result));
        } else {
            console.error("Failed to fetch bonus progress details");
        }
    } catch (error) {
        console.error("Error fetching bonus progress details:", error);
    }
};
