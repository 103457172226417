import { get, put, patch } from "$ACTIONS/TlcRequest";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { realyNameReg } from "$ACTIONS/reg";
import { message } from "antd";
// 获取用户账户信息以及设置为LocalStorage
export async function getMemberInfo(refresh) {
    const localMemberInfo = localStorage.getItem("memberInfo");
    let memberInfo = {};

    if (localMemberInfo === null || localMemberInfo === "" || refresh) {
        try {
            const res = await get(ApiPort.GETMemberlistAPI);
            if (res) {
                localStorage.setItem(
                    "memberCode",
                    res.result.memberInfo.memberCode
                );

                if (res && res.isSuccess) {
                    res.result.memberInfo.contacts.forEach((val) => {
                        res.result.memberInfo["isVerified" + val.contactType] =
                            [val.contact, val.status === "Verified"];
                    });

                    const {
                        documentID,
                        dob,
                        address,
                        isVerifiedEmail,
                        isVerifiedPhone,
                    } = res.result.memberInfo;
                    /* 提款验证步骤，當前由Central Payment傳參出來判斷，目前未使用 withdrawalVerifyStep 參數*/
                    if (isVerifiedPhone && !isVerifiedPhone[1]) {
                        /* 第1步 邮箱验证 */
                        res.result.memberInfo.withdrawalVerifyStep = 1;
                    } else if (isVerifiedEmail && !isVerifiedEmail[1]) {
                        /* 第2步 手机验证 */
                        res.result.memberInfo.withdrawalVerifyStep = 2;
                    } else {
                        /* 提款验证完成 */
                        res.result.memberInfo.withdrawalVerifyStep = false;
                    }

                    Object.assign(
                        memberInfo,
                        res.result.memberInfo,
                        res.result.memberNewInfo
                    );
                    console.log("------------------>", memberInfo);
                }
                const memberInfoString = JSON.stringify(memberInfo);
                localStorage.setItem(
                    "memberInfo",
                    memberInfoString === "{}" ? "" : memberInfoString
                );

                //Set Piwik Custom Dimensions (Session)
                if (typeof _paq === "object") {
                    _paq.push(["setUserId", memberInfo.memberCode]);  // [CXF1-7567] piwik userid set to memberCode
                    _paq.push(["setCustomDimensionValue", 2, memberInfo.regWebSite]);  //Device Id
                    _paq.push(["setCustomDimensionValue", 3, memberInfo.memberPaymentRisk.paymentRisk]) //Payment Risk;
                    _paq.push(["setCustomDimensionValue", 4, memberInfo.referralCode || ""]) //Affilate Code;
                    _paq.push(["setCustomDimensionValue", 5, memberInfo.isDeposited ? "1" : "0"]) //Deposite Status;
                }

                //首选钱包
                localStorage.setItem("PreferWallet", memberInfo.preferWallet);
                return memberInfo;
            }
        } catch (error) {
            console.log("getMemberInfo(GETMemberlistAPI) error:", error);
        }
    } else {
        return JSON.parse(localMemberInfo);
    }
}

export function setMemberInfo(data, call) {
    patch(ApiPort.PATCHMemberlistAPI, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("setMemberInfo error:", error);
            return null;
        });
}

// 获取密保问题
export function getQuestion(call) {
    get(ApiPort.GETQuestions)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("getQuestion error:", error);
            return null;
        });
}

/**
 * @description: 提交地址 生日 资料 提款验证
 * @param {*} userInfo
 * @param {*} call
 * @return {*}
 */
export function setMemberInfoPut(data, call) {
    put(ApiPort.PUTMemberlistAPI, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("setMemberInfo error:", error);
            return null;
        });
}
