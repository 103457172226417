/*
 * @Author: Alan
 * @Date: 2023-01-09 16:20:08
 * @LastEditors: Alan
 * @LastEditTime: 2023-05-23 13:57:52
 * @Description: 头部注释
 * @FilePath: \F1-M2-WEB-Code\config\Globalfun.js
 */
import { childrenStags } from "$ACTIONS/Host.config";
/* 全局函数初始化 */
if (process.browser) {
    // webp支持否判斷初始化
    global.isSupportWebp = false;
    let piwikTimer = null;
    let piwikUrl = [];

    function _avoidExecutePiwikOver1TimeInShortTime(nameFn, fn) {
        return function(...args) {
            const {
                avoid = _avoidExecutePiwikOver1TimeInShortTime,
                pool = avoid.poolCoolDown,
                check = nameFn + ':' + JSON.stringify(args),
            } = {};

            if (pool.includes(check)) { return; }

            fn.apply(this, args); // execute immediatly

            pool.push(check); // enable cool-down

            setTimeout((function(check) {
                return () => {
                    avoid.poolCoolDown = avoid.poolCoolDown.filter(v=>v!==check) // disable cool-down
                };
            })(check), 333);
        };
    }
    _avoidExecutePiwikOver1TimeInShortTime.poolCoolDown = [];

    function pushUrl(_paq, url, trackingTitle = null, isTrackPageView) {
        // if (!window.piwikLoadFinished && ~window.location.href.indexOf(url)) {
        //     return;
        // }
        _paq.push(["setDocumentTitle", trackingTitle]);
        _paq.push(["setCustomUrl", url]);
        isTrackPageView && _paq.push(["trackPageView"]);
    }

    ~(me => global.Pushgtagpiwikurl = _avoidExecutePiwikOver1TimeInShortTime('Pushgtagpiwikurl', me))(
    global.Pushgtagpiwikurl = (
        url,
        trackingTitle = false,
        isTrackPageView = true
    ) => {
        if (!url) {
            return;
        } else {
            url = window.location.origin + "/th/" + url + "/";
        }
        if (typeof _paq === "object") {
            pushUrl(_paq, url, trackingTitle, isTrackPageView);
        } else {
            piwikUrl.push([url, trackingTitle, isTrackPageView]);
            clearInterval(piwikTimer);
            piwikTimer = setInterval(() => {
                if (typeof _paq === "object") {
                    clearInterval(piwikTimer);
                    Array.isArray(piwikUrl) &&
                        piwikUrl.length &&
                        piwikUrl.forEach((v) => {
                            pushUrl(_paq, ...v);
                        });
                    piwikUrl = [];
                }
            }, 1000);
        }
    });
    ~(me => global.Pushgtagdata = _avoidExecutePiwikOver1TimeInShortTime('Pushgtagdata', me))(
    global.Pushgtagdata = (
        eventCategory,
        action,
        name,
        value = false,
        customVariableArr = []
    ) => {
        /*PiWA 追中*/
        console.log("PiWA 追中:", eventCategory, action, name, value, customVariableArr);
        if (eventCategory == undefined) {
            return;
        }
        let data = eventCategory.replace(/[&\|\\\*^%/$#@\-]/g, "");
        console.log(data, "追中------benji");
        if (typeof _paq === "object") {
            customVariableArr.length &&
                customVariableArr.forEach((variableItem, i) => {
                    variableItem.customVariableKey &&
                        _paq.push([
                            "setCustomVariable",
                            i + 1,
                            variableItem.customVariableKey,
                            variableItem.customVariableValue,
                            "page",
                        ]);
                });
            if (!value) {
                _paq.push([
                    "trackEvent",
                    data,
                    action ? action : "touch",
                    name,
                ]);
            } else {
                _paq.push([
                    "trackEvent",
                    data,
                    action ? action : "touch",
                    name,
                    value,
                ]);
            }
            customVariableArr.length &&
                customVariableArr.forEach((variableItem, i) =>
                    _paq.push(["deleteCustomVariable", i + 1, "page"])
                );
        }
    });

    
   let MAX = 25, CUR = 0, did;
    const t = setInterval(() => {
        if (did || CUR >= MAX) { 
            clearInterval(t); 
            return; 
        }
        CUR += 1;

        if (typeof _paq === "object") {
            _paq.push(["disableLinkTracking"]); // 禁止 <a> 觸發 piwik 避免多餘紀錄
        }
    }, 400);

    let StagingApi = Boolean(
        [
            "p5stag.fun88.biz",
            ...childrenStags,
            "localhost:8282",
            "127.0.0.1:8080",
        ].find((v) => global.location.href.includes(v))
    );
    setTimeout(() => {
        let e2src = "https://e2.platform88798.com/E2/EagleEye.js";
        if (StagingApi) {
            e2src = "https://ytl.ylyofb45n.com/E2/EagleEye.js";
        }
        // let script1 = document.createElement('script');
        // script1.src = 'https://mpsnare.iesnare.com/snare.js';
        // script1.type = 'text/javascript';
        // document.querySelectorAll('body')[0].appendChild(script1);
        let script2 = document.createElement("script");
        script2.src = e2src;
        script2.type = "text/javascript";
        document.querySelectorAll("body")[0].appendChild(script2);
    }, 5000);

    if (StagingApi) {
        /*PiWA 追中*/
        (function (window, document, dataLayerName, id) {
            (window[dataLayerName] = window[dataLayerName] || []),
                window[dataLayerName].push({
                    start: new Date().getTime(),
                    event: "stg.start",
                });
            var scripts = document.getElementsByTagName("script")[0],
                tags = document.createElement("script");

            function stgCreateCookie(a, b, c) {
                var d = "";
                if (c) {
                    var e = new Date();
                    e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3),
                        (d = "; expires=" + e.toUTCString());
                }
                document.cookie = a + "=" + b + d + "; path=/";
            }

            var isStgDebug =
                (window.location.href.match("stg_debug") ||
                    document.cookie.match("stg_debug")) &&
                !window.location.href.match("stg_disable_debug");
            stgCreateCookie(
                "stg_debug",
                isStgDebug ? 1 : "",
                isStgDebug ? 14 : -1
            );

            var qP = [];
            dataLayerName !== "dataLayer" &&
                qP.push("data_layer_name=" + dataLayerName),
                isStgDebug && qP.push("stg_debug");
            var qPString = qP.length > 0 ? "?" + qP.join("&") : "";

            (tags.async = !0),
                (tags.src =
                    "//analytics.ravelz.com/containers/" +
                    id +
                    ".js" +
                    qPString),
                scripts.parentNode.insertBefore(tags, scripts);

            !(function (a, n, i) {
                a[n] = a[n] || {};
                for (var c = 0; c < i.length; c++)
                    !(function (i) {
                        (a[n][i] = a[n][i] || {}),
                            (a[n][i].api =
                                a[n][i].api ||
                                function () {
                                    var a = [].slice.call(arguments, 0);
                                    "string" == typeof a[0] &&
                                        window[dataLayerName].push({
                                            event: n + "." + i + ":" + a[0],
                                            parameters: [].slice.call(
                                                arguments,
                                                1
                                            ),
                                        });
                                });
                    })(i[c]);
            })(window, "ppms", ["tm", "cm"]);
        })(
            window,
            document,
            "dataLayer",
            "43b16948-0ff6-4dc5-b70e-5808bbb12d31"
        );
        /*PiWA 追中*/

        /**
         * ST  aacd115c-d97b-4c88-b763-b62aa1d1c56d
         * Prod  2948cb02-cafc-40f0-adad-cc74eec891a8
         */
    } else {
        /*PiWA 追中*/
        (function (window, document, dataLayerName, id) {
            (window[dataLayerName] = window[dataLayerName] || []),
                window[dataLayerName].push({
                    start: new Date().getTime(),
                    event: "stg.start",
                });
            var scripts = document.getElementsByTagName("script")[0],
                tags = document.createElement("script");

            function stgCreateCookie(a, b, c) {
                var d = "";
                if (c) {
                    var e = new Date();
                    e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3),
                        (d = "; expires=" + e.toUTCString());
                }
                document.cookie = a + "=" + b + d + "; path=/";
            }

            var isStgDebug =
                (window.location.href.match("stg_debug") ||
                    document.cookie.match("stg_debug")) &&
                !window.location.href.match("stg_disable_debug");
            stgCreateCookie(
                "stg_debug",
                isStgDebug ? 1 : "",
                isStgDebug ? 14 : -1
            );

            var qP = [];
            dataLayerName !== "dataLayer" &&
                qP.push("data_layer_name=" + dataLayerName),
                isStgDebug && qP.push("stg_debug");
            var qPString = qP.length > 0 ? "?" + qP.join("&") : "";

            (tags.async = !0),
                (tags.src =
                    "//analytics.ravelz.com/containers/" +
                    id +
                    ".js" +
                    qPString),
                scripts.parentNode.insertBefore(tags, scripts);

            !(function (a, n, i) {
                a[n] = a[n] || {};
                for (var c = 0; c < i.length; c++)
                    !(function (i) {
                        (a[n][i] = a[n][i] || {}),
                            (a[n][i].api =
                                a[n][i].api ||
                                function () {
                                    var a = [].slice.call(arguments, 0);
                                    "string" == typeof a[0] &&
                                        window[dataLayerName].push({
                                            event: n + "." + i + ":" + a[0],
                                            parameters: [].slice.call(
                                                arguments,
                                                1
                                            ),
                                        });
                                });
                    })(i[c]);
            })(window, "ppms", ["tm", "cm"]);
        })(
            window,
            document,
            "dataLayer",
            "1855a1ff-b8a9-4b99-a953-a2c45ec06594"
        );
        /*PiWA 追中*/
    }
}
